@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800&display=swap');

/* body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #171321;
} */
.mainUM {
  font-family: 'DM Sans', sans-serif;

  display: flex;
  flex: 1 1 auto;
  background: radial-gradient(
      50.08% 44.51% at 100% 100%,
      rgba(169, 137, 245, 0.2) 0,
      rgba(119, 89, 194, 0) 100%
    ),
    radial-gradient(60.76% 48.01% at 0 100%, rgba(226, 67, 41, 0.2) 0, rgba(226, 67, 41, 0) 100%),
    #f6f3fe;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.logoUM {
  margin-bottom: 1em;
  max-width: 350px;
}
.contentUM {
  max-width: 845px;
  text-align: center;
  position: relative;
  z-index: 11;
  padding: 0 1em;
  font-family: 'DM Sans', sans-serif;
}
.mainUM h4 {
  font-size: 2.5em;
  margin: 0;

  padding: 0;
  background: linear-gradient(89.95deg, #ff9047 3.13%, #7e4fef 66.86%, #592ac8 123.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'DM Sans', sans-serif;
}
.mainUM p {
  font-size: 1.05em;
  line-height: 175%;
  font-weight: 500;
  margin-top: 1em;
  font-family: 'DM Sans', sans-serif;
}
.mainUM p span {
  font-weight: 600;
  font-family: 'DM Sans', sans-serif;
}
.mainUM .highlight {
  color: #0b173d;
  font-family: 'DM Sans', sans-serif;
  background: unset !important;
}
.highlight {
  background: unset !important;
  padding: unset !important;
}
.mainUM p a {
  font-weight: 600;
  color: #7759c2;
  text-decoration: none;
  font-family: 'DM Sans', sans-serif;
}
.mainUM p a:hover {
  text-decoration: underline;
}
.mainUM .connect-img {
  display: flex;
  width: 100%;
  padding: 1em 0;
  position: relative;
  z-index: 11;
}
.mainUM .connect-img img {
  width: 100%;
}

.mainUM .bg {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.mainUM .bg .Polygon-1 {
  position: absolute;
  top: -30%;
  left: 30%;
  color: #ebecf0;
  max-width: 1000px;
  transform: translateX(-50%);
  max-width: 50%;
  animation: rotate 100s normal linear infinite;
  opacity: 0.5;
  z-index: 0;
}
.mainUM .bg .Polygon-2 {
  position: absolute;
  left: 10%;
  top: -15%;
  max-width: 40%;
  color: #ebecf0;
  transform: translateX(-50%);
  animation: rotate2 110s normal linear infinite;
  opacity: 0.05;
  z-index: 0;
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes rotate2 {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg) scale(2);
  }

  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg) scale(2);
  }
}
