@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
$base-color:#181c32;
$primary:#3E3EF4;
$font-family:'Figtree', sans-serif;
$mute-color:#88949E;
$success:#0C9F34;
//begin: Css In the theme
// body, html{
//     height: 100%;
//     margin: 0;
//     padding: 0;
// }
.login-content{
    display: flex;
    flex-direction: column;
    color:$base-color ;
    font-family: $font-family;
    font-size: 15px;
}
.d-flex{
    display: flex;
}
.flex-root{
    flex: 1;
}
.flex-column{
    flex-direction: column;
}
.flex-column-fluid{
    flex: 1 0 auto;
}
.flex-center {
    justify-content: center;
    align-items: center;
}
.flex-wrap {
    flex-wrap: wrap;
}
@media (min-width: 992px){
    .flex-lg-row{
    flex-direction:row;
}
}


$body-bg:#F8FAFB;
.fluid-area{
    position: relative;
    z-index: 1;
}
.login-content{
    position: relative;
    
    .bg-items{
         position: absolute;
    z-index: -1;
     background: $body-bg;
     width: 100%;
     height: 100%;
     overflow: hidden;
     &::before{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background: url(../../../localMedia/img/dot_pattern.svg);
        background-position: 0 0;
        background-size: auto;
        background-repeat: repeat;
        opacity: .4;
        z-index: 2;
     }
     .circle{
       
        border-radius: 250px;
        position: absolute;
        z-index: 0;
        transition: all .5s ease-in-out;
        
        &.circle1{
             filter: blur(175px);
            width: 728px;
            height: 728px;
            background: #FFB8B8;
            // will-change: move;
            // animation: move 20s infinite ease-out;
            transform: translateX(-50%);
        }
        &.circle2{
             filter: blur(175px);
            width: 850px;
            height: 850px;
            background: #25C16F;
            // will-change: move2;
            // animation: move2 35s infinite ease-out;
            opacity: .3;
        }
        &.circle3{
             filter: blur(175px);
            width: 750px;
            height: 750px;
            background: #F8BD54;
            // animation: move3 30s infinite ease-out;
            opacity: .35;
        }
     }
    }
   
}
.mask-paths{
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    
}
.lft-cover{
    width: 40%;
    position: relative;
    flex:1 auto;
    overflow: hidden;
    .image-shape{
        width: 100%;
        height: 100%;
        min-height: 650px;
        top: 50%;
        background: url(../../../localMedia/img/timesheet-cover-img.png) no-repeat;
        background-position:left center;
        background-size:100% auto;
        position: absolute;
        transform: translateY(-50%);
        &::before, &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            
        }
        &::before{
            background: url(../../../localMedia/img/curve-1.svg) no-repeat;
            background-size: contain;
            top: 30%;
            transform: translateY(-25%);
            right: 30px;
        }
        &::after{
            background: url(../../../localMedia/img/curve-2.svg) no-repeat;
            background-size: contain;
            bottom: -25px;
            transform: translateY(20%);
            
            right: 15px;
        }
    }
    // .circle-person-image{
    //     position: absolute;
    //     width: 100%;
    //     top: 50%;
    //     transform: translateY(-50%);
    // }
}
.form-section{
    flex: 1;
    min-width: 38%;
    h2{
        font-size: 2.154rem;
        font-weight: 700;
    }
    h5{
        font-size: 1.54rem;
        margin-bottom: .92rem;
    }
    .login-descrip{
        font-size: 1.17rem;
        color: $mute-color;
        margin-bottom: 1.85rem;
    }
    .form{
        display: flex;
        flex: 1 1 auto;
        padding: 0 2.5rem;
        max-width: 420px;
        margin: 0 auto;
        flex-direction: column;
    .login{
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    position: relative;
    z-index: 1;
    .brand-logo{
        display: flex;
        margin-bottom: 1.84rem;
        img{
            max-height: 45px;
            width: 250px;
        }
    }
    .login-form{
        .form-floating{
            position: relative;
            .form-control{
                border-radius:0;
                &:focus{
                    box-shadow: none;
                    border-color: #dee2e6;
                }
            }
            
            img{
                position: absolute;
                width: 20px;
                height: 20px;
                left: 1rem;
                top: 50%;
                transform: translateY(-50%);
            }
            label{
                left: 2.9rem;
                padding-left: 0;
                color: $mute-color;
            }
            &:first-child{
               .form-control{
                border-top-left-radius:0.375rem;
                border-top-right-radius:0.375rem;
            } 
            }
            &:last-child{
               .form-control{
                border-bottom-left-radius:0.375rem;
                border-bottom-right-radius:0.375rem;
            } 
            }
            &.active{
                label{
                    &::after{
                        display: none;
                    }
                }
                 .form-control{
                    background:url(../../../localMedia/img/check-mark.svg) #EEF2F5;
                    background-repeat: no-repeat;
                    background-size: 24px;
                    background-position: center right 10px;
                 }
            }
        }
    }
    .form-check-input{
        border-radius: 30px;
        border-color: $mute-color;
        &:focus{
                box-shadow:0 0 0 0.25rem lighten($success, 55%)
            }
        &:checked{
            background-color: $success;
            border-color: $success;
        }

    }
    .form-check-label, .forgot-pwd{
        color: $mute-color;
        font-size: 1.11rem;
    }
    .forgot-pwd{
        text-decoration: none;
        &:hover{
            color: $primary;
        }
    }
    .custom-btn{
        padding: 12px 25px;
        font-size: 1.23rem;
        text-transform: uppercase;
        background: $primary;
        font-weight: 500;
        transition: all .35s ease-in-out;
        border: none;
        &:hover{
            background: darken($primary, 8%);
        }
    }
    }
    }
}
.app-download{
    p{
        margin: 1.23rem 0;
        font-size: 1rem;
        color: $mute-color;
    }
    ul{
        display: flex;
        list-style-type: none;
        gap: 1rem;
        margin: 0;
        padding: 0;
        li{
            img{
                min-width: 145px;
            }
        }
    }
}
.poweredby{
    padding: 1.5rem 1.5rem 2.5rem;
    flex-direction: column;
    p{
        font-size: .85rem;
        color: $mute-color;
        margin-bottom: .1rem;
    }
    img{
        height: auto;
    max-width: 240px;
    }
}

@keyframes move{
  0% {
    top: 95vh;
    left: 15%;
  }
  35% {
    top: 100vh;
    left: 25%;
    transform: scale(1.5);
    opacity: 1;
  }
  70% {
    top: 95vh;
    left: 0;
    opacity: .5;
  }
  100% {
    top: 95vh;
    left: 15%;
  }
}
@keyframes move2{
  0% {
    top: -850px;
    left: 50%;
  }
  35% {
    top: -900px;
    left: 100%;
    transform: scale(1.5);
  }
  70% {
    top: -850px;
    left: 15%;
    filter: blur(125px);
  }
  100% {
    top: -850px;
    left: 50%;
  }
}
@keyframes move3{
  0% {
    top: 75%;
    left: 90%;
  }
  50% {
    top: 45%;
    left: 95%;
    opacity: .5;
    transform: scale(1.5);
  }
  100% {
    top: 75%;
    left: 90%;
  }
}

@media (max-width: 1340px){
    .lft-cover .image-shape{
        background-position: right center;
        background-size: 125% auto;
    }
}
@media (max-width:996px){
    .lft-cover{
        display: none !important;
    }
}
.animate{
    position: absolute;
    display: none;
    z-index: 0;
    width: 100%;
    overflow: hidden;
    height: 100%;
.animate_circles {
  height: 1000px;
  position: absolute;
  z-index: 0;
 left: 110%;
 top:50%;
 transform: translate(-50%, -50%);
  width: 1000px;
  span{
    animation: growAndFade 10s infinite ease-out;
    border:solid 1px $primary;
    opacity: .05;
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    &.circle1 {
    animation-delay: .5s;    
  }
  &.circle2 {
    animation-delay:1s; 
  }
  &.circle3 {
    animation-delay: 1.5s;
  }
  &.circle4 {
    animation-delay: 2s;
  }
  &.circle5 {
    animation-delay: 2.5s;
  }
  &.circle6 {
    animation-delay:3s;
  }
  &.circle7 {
    animation-delay: 3.5s;
  }
  &.circle8 {
    animation-delay: 4s;
  }
  &.circle9 {
    animation-delay: 4.5s;
  }
  &.circle10 {
    animation-delay: 5s;
  }
  &.circle11 {
    animation-delay: 5.5s;
  }
  &.circle12 {
    animation-delay: 6s;
  }
  &.circle13 {
    animation-delay: 6.5s;
  }
  &.circle14 {
    animation-delay: 7s;
  }
  &.circle15 {
    animation-delay: 7.5s;
  }
  &.circle16 {
    animation-delay: 8s;
  }
  &.circle17 {
    animation-delay: 8.5s;
  }
  &.circle18 {
    animation-delay: 9s;
  }
  &.circle19 {
    animation-delay: 9.5s;
  }
  &.circle20 {
    animation-delay: 10s;
  }

}
}
}

@keyframes growAndFade {
  0% {
    opacity: .12;
    transform: scale(0);
    border-width: 1px;
  }
  100% {
    opacity: 0;
    transform: scale(2);
    border-width: 0px;
  }
}

.clock {
  position: absolute;
  opacity: .1;
  height: 150px;
  width: 150px;
  background: white;
  box-sizing: border-box;
  border-radius: 100%;
  border: 10px solid $mute-color;
  box-shadow: 5px 5px 30px rgba(0,0,0,.5), -5px -5px 30px rgba(255,255,255,.5);
  position: absolute;
  top: 75%;
  z-index: 0;
  right: 32%;
  transform: translateY(-15%);
  margin: auto;
  .top {
    position: absolute;
    width: 3px;
    height: 8px;
    background: #262626;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .right {
    position: absolute;
    width: 8px;
    height: 3px;
    background: #262626;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
  }
  .bottom {
    position: absolute;
    width: 3px;
    height: 8px;
    background: #262626;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  }
  .left {
    position: absolute;
    width: 8px;
    height: 3px;
    background: #262626;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
  }
  .center {
    height: 6px;
    width: 6px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #262626;
    border-radius: 100%;
  }
//   .shadow {
//     height: 150px;
//     width: 150px;
//     position: absolute;
//     left: 60px;
//     top: 60px;
//     transform: rotate(135deg);
//     background: linear-gradient(transparent, rgba(#000, 0.1));
//   }
  .hour {
    width: 3px;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    //animation: time 86400s infinite linear;
    animation: time 60s infinite linear;
    &:before {
      position: absolute;
      content: "";
      background: #262626;
      height: 45px;
      width: 2px;
      top: 20px;
    }
  }
  .minute {
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    //animation: time 3600s infinite linear;
    animation: time 30s infinite linear;
    &:before {
      position: absolute;
      content: "";
      background: #262626;
      height: 30px;
      width: 2px;
      top: 35px;
    }
  }
  .second {
    width: 2px;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    //animation: time 60s infinite linear;
    animation: time 15s infinite linear;
    &:before {
      position: absolute;
      content: "";
      background: #fd1111;
      height: 35px;
      width: 2px;
      top: 30px;
    }
  }
}

@keyframes time {
  to {
    transform: rotate(360deg);
  }
}

.calendar{
    display: flex;
    
}